<template>
  <div>
    <div><span><a href="/movies">風景や動植物の動画を見る</a></span></div>
    <div v-if="instagramView"><span><a href="/photos#instagram">南アルプスに関するインスタグラムの投稿を見る</a></span></div>
    <div><span><a href="/photos">投稿されたみんなのお気に入りの写真を見る</a></span></div>
    <div><span><a href="/articles">南アルプスに関する記事を読む</a></span></div>
  </div>
</template>

<script>
export default {
  name: "tab-content-1",
  props: [ 'instagramView' ]

}
</script>
